import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SendSingleInfiniteRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { useInViewScrollDown } from '@cfra-nextgen-frontend/shared/src/hooks/useInViewScrollDown';
import { useMakeIndependent } from '@cfra-nextgen-frontend/shared/src/hooks/useMakeIndependent';
import { Layout } from '@cfra-nextgen-frontend/shared/src/index';
import { getSumOfAllValues } from '@cfra-nextgen-frontend/shared/src/utils/arrays';
import { Box, SxProps } from '@mui/material';
import {
    GetOptionsBlankContainer,
    GetOptionsContainer,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types';
import React, { Dispatch, forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseInfiniteQueryResult } from 'react-query';

type InfiniteOptionsProps<T, K> = {
    getRowsData?: (data: T) => Array<K> | undefined;
    infiniteRequestParams: Parameters<SendSingleInfiniteRequest>;
    outerSetOptionsCondition?: Boolean;
    RowComponent: React.FC<{ rowData: K }>;
    rowsKeyPrefix?: string;
    scrollThresholdPx: number;
    noResultsFoundBoxSxProps: SxProps;
    externalCallback?: (data?: Array<T>) => void;
    OptionsContainer: ReturnType<GetOptionsContainer | GetOptionsBlankContainer>;
    optionsContainerProps?: any;
    loadingContainerStyles?: SxProps;
    outerContainerRef?: React.RefObject<HTMLDivElement>;
    setIsLoading?: (isLoading: boolean) => void;
    setNumberOfResults?: (numberOfResults: number) => void;
};

export type InfiniteOptionsRef = {
    resetOptions?: () => void;
};

type Pages<T> = Array<T> | null;

export function fetchNewPageOrHandleExistingPageExceptFirst<T>({
    infiniteQueryResult,
    setPages,
}: {
    infiniteQueryResult?: UseInfiniteQueryResult<T>;
    setPages: Dispatch<React.SetStateAction<Pages<T>>>;
}) {
    // fetch new pages from api
    // handle pages from cache
    // works for all the pages starting from second page (first page is not included here)
    if (
        !infiniteQueryResult ||
        infiniteQueryResult.isFetchingNextPage ||
        infiniteQueryResult.isLoading ||
        !infiniteQueryResult?.data?.pages ||
        infiniteQueryResult.data.pages.length <= 1 // run only if we already rendered at least one page
    ) {
        return;
    }

    setPages((previousPages) => {
        // fetch data only if rendered all the pages from cache
        if (previousPages && previousPages.length === infiniteQueryResult?.data?.pages.length) {
            // trigger fetching new page from api
            infiniteQueryResult.fetchNextPage();
            return previousPages;
        }

        // cover the error case for typescript, this shouldn't happen
        if (!previousPages || previousPages.length > infiniteQueryResult?.data?.pages.length) {
            throw new Error('InfiniteOptions component exception. Pages cache contains unexpected amount of data.');
        }

        // add next page from cache
        return [...previousPages, infiniteQueryResult?.data?.pages[previousPages.length]];
    });
}

export const InfiniteOptions = forwardRef(
    <T, K>(
        {
            getRowsData,
            infiniteRequestParams,
            outerSetOptionsCondition = true,
            RowComponent,
            rowsKeyPrefix,
            scrollThresholdPx,
            noResultsFoundBoxSxProps,
            externalCallback,
            OptionsContainer,
            optionsContainerProps = {},
            loadingContainerStyles = {},
            outerContainerRef,
            setIsLoading,
            setNumberOfResults,
        }: InfiniteOptionsProps<T, K>,
        refToForward: React.Ref<InfiniteOptionsRef>,
    ) => {
        const clearingPagesDependency = useMemo(() => {
            const { config: notUsed1, ...restSearchByParams } = infiniteRequestParams[0];
            const { getNextPageParam: notUsed2, ...restInfiniteRequestParamsConfig } = infiniteRequestParams[1];
            // has unnecessary re-rendering in case of not use JSON.stringify here
            return JSON.stringify([restSearchByParams, restInfiniteRequestParamsConfig]);
        }, [infiniteRequestParams]);

        const [pages, setPages] = useState<Pages<T>>(null);

        // Reset pages from outside, commented out as not used already
        // but can be used in the future
        // useImperativeHandle(refToForward, () => ({
        //     resetOptions() {
        //         setPages(null);
        //     },
        // }));

        const optionsContainerRef = useRef<HTMLDivElement>(null);
        const thresholdInPxRef = useRef<number>(scrollThresholdPx);

        const { sendSingleInfiniteRequest } = useContext(ProjectSpecificResourcesContext);

        if (!sendSingleInfiniteRequest) {
            throw new Error('InfiniteOptions component exception. sendSingleInfiniteRequest is not provided.');
        }

        const infiniteQueryResult = sendSingleInfiniteRequest?.<T>(
            infiniteRequestParams[0],
            infiniteRequestParams[1],
        ) as UseInfiniteQueryResult<T> | undefined;

        const { independentValue: fetchNextPageRef } = useMakeIndependent({
            valueGetter: () => () =>
                fetchNewPageOrHandleExistingPageExceptFirst({
                    infiniteQueryResult,
                    setPages,
                }),
            defaultValue: () => {},
        });

        const { calculateInView, unblockAndCalculateInView, resubscribeEvents } = useInViewScrollDown({
            containerRef: outerContainerRef,
            elementRef: optionsContainerRef,
            callbackRef: fetchNextPageRef,
            thresholdInPxRef,
        });

        useEffect(() => {
            setPages(null); // clear all pages on request params change
            // fix issue infinite scroll is not working if parent was remounted
            resubscribeEvents();
            // keep clearPagesDependency in the dependencies
            // to clear pages on request params change
        }, [clearingPagesDependency, resubscribeEvents]);

        // handle new pages from api starting from second page (first page is not included here)
        useEffect(() => {
            setPages((previousPages) => {
                if (
                    !infiniteQueryResult ||
                    infiniteQueryResult.isFetchingNextPage ||
                    infiniteQueryResult.isLoading ||
                    !previousPages ||
                    previousPages.length === 0 || // run only if we already rendered at least one page
                    infiniteQueryResult?.data?.pages.length !== previousPages.length + 1 // run only for the new page
                ) {
                    return previousPages;
                }

                const newPage = infiniteQueryResult?.data?.pages.at(-1);

                if (!newPage) {
                    return previousPages;
                }
                // add new page from api
                return [...previousPages, newPage];
            });
        }, [infiniteQueryResult]);

        // useInViewScrollDown triggers for all pages
        useEffect(() => {
            if (!pages) {
                return;
            }

            // trigger useInViewScrollDown for the first page
            if (pages.length === 1) {
                calculateInView();
                return;
            }

            // trigger useInViewScrollDown for all the pages starting from second page
            unblockAndCalculateInView();
        }, [unblockAndCalculateInView, pages, calculateInView]);

        // handle only first page from both api and cache
        useEffect(() => {
            if (
                !infiniteQueryResult?.isLoading &&
                !infiniteQueryResult?.isFetchingNextPage &&
                infiniteQueryResult?.data &&
                infiniteQueryResult?.data.pages &&
                infiniteQueryResult?.data.pages.length > 0 &&
                outerSetOptionsCondition
            ) {
                // set first page
                setPages((previousPages) =>
                    previousPages === null ? [infiniteQueryResult?.data.pages[0]] : previousPages,
                );

                // suppose to handle results count in outer component
                externalCallback?.(infiniteQueryResult?.data.pages);
            }
        }, [
            infiniteQueryResult?.data,
            infiniteQueryResult?.isLoading,
            outerSetOptionsCondition,
            infiniteQueryResult?.isFetchingNextPage,
            externalCallback,
            calculateInView,
        ]);

        // handle alert for loading state
        useEffect(() => {
            if (infiniteQueryResult?.isLoading) {
                externalCallback?.(undefined);
            }
        }, [externalCallback, infiniteQueryResult?.isLoading]);

        const renderedPages = useMemo(() => {
            if (!getRowsData || !pages) {
                return null;
            }

            return (
                <>
                    {React.Children.toArray(
                        pages.map((data, dataIndex) => {
                            return React.Children.toArray(
                                getRowsData(data)?.map((option, optionIndex) => {
                                    const key = `${rowsKeyPrefix}-${dataIndex}-${optionIndex}-options`;
                                    return <RowComponent rowData={option} key={key} />;
                                }),
                            );
                        }),
                    )}
                </>
            );
        }, [RowComponent, getRowsData, pages, rowsKeyPrefix]);

        const isLoading = useMemo(() => {
            return (
                infiniteQueryResult?.isLoading ||
                (infiniteQueryResult?.isFetchingNextPage && infiniteQueryResult?.hasNextPage) ||
                false
            );
        }, [infiniteQueryResult?.isLoading, infiniteQueryResult?.isFetchingNextPage, infiniteQueryResult?.hasNextPage]);

        const numberOfResults = useMemo(() => {
            if (!pages || !getRowsData) {
                return 0;
            }

            return getSumOfAllValues(pages.map((data) => getRowsData?.(data)?.length || null)) || 0;
        }, [pages, getRowsData]);

        useEffect(() => {
            setNumberOfResults?.(numberOfResults);
        }, [numberOfResults, setNumberOfResults]);

        useEffect(() => {
            setIsLoading?.(isLoading);
        }, [setIsLoading, isLoading]);

        return (
            <OptionsContainer
                key='infiniteOptionsContainerElementRef'
                ref={optionsContainerRef}
                {...optionsContainerProps}>
                {!infiniteQueryResult?.isLoading && renderedPages}
                {!infiniteQueryResult?.isLoading && numberOfResults === 0 && (
                    <Box sx={noResultsFoundBoxSxProps}>
                        <ResearchDescriptionText>No results found</ResearchDescriptionText>
                    </Box>
                )}
                {isLoading ? (
                    <Box sx={{ width: '100%', paddingTop: '5px', ...loadingContainerStyles }}>
                        <Layout.Skeleton
                            height='10px'
                            sx={{
                                '&.MuiSkeleton-root:empty:before': {
                                    content: '""', // Removes unicode symbol, resolves unexpected scrollbar for the parent container
                                },
                            }}
                        />
                    </Box>
                ) : undefined}
            </OptionsContainer>
        );
    },
) as <T, K>(props: InfiniteOptionsProps<T, K> & { ref?: React.Ref<InfiniteOptionsRef> }) => JSX.Element;
